<template>
  <div class="maps-area">
    <div class="md-layout md-gutter">
      <div
        class="md-layout-item md-size-25 md-small-size-50 md-xsmall-size-100"
      >
        <md-field>
          <label for="tileType">{{ $t('map.tile_types') }}</label>
          <md-select
            v-model="selectedTileType"
            @md-selected="onTileTypeChanged"
            name="tileType"
            id="tileType"
          >
            <md-option v-for="tile of tileTypes" :key="tile" :value="tile">
              {{ $t(`tile_types.${tile}`) }}
            </md-option>
          </md-select>
        </md-field>
      </div>
      <div
        class="md-layout-item md-layout-item md-size-25 md-small-size-50 md-xsmall-size-100"
      >
        <md-field>
          <label for="vectorType">{{ $t('map.vector_types') }}</label>
          <md-select
            v-model="selectedVectorsType"
            @md-selected="onVectorTypeChanged"
            name="vectorType"
            id="vectorType"
          >
            <md-option
              v-for="vector of vectorTypes"
              :key="vector.key"
              :value="vector.key"
            >
              {{ $t(vector.value) }}
            </md-option>
          </md-select>
        </md-field>
      </div>
    </div>
    <div class="main-zone">
      <OlMap
        v-if="isMapDefaultParamsLoaded"
        class="map-zone"
        :style="{height: isShowRoadDetails ? 'calc( 100% - 250px )' : '100%'}"
        ref="mapInstance"
        :tileLayer="selectedTileType"
        :vectorLayer="selectedVectorsType"
        :extent="mapExtent"
        :mapHighlightedFeatureItem="mapHighlightedFeatureItem"
        :mapDefaultParams="mapDefaultParams"
        :filterOptions="filterQueryDef"
        :vectorLegend="selectedVectorLegend"
        :trackedEvents="['singleclick']"
        @change-map-state="onChangeMapState"
        @changeTileList="onChangeTileList"
        @vectorSourceIsLoading="onMapVectorSourceIsLoading"
        @mapEvent="setMapHighlightedFeatureItem"
        @reloadVectorData="reloadVectorData"
      ></OlMap>
      <!--transition name="fade"-->
      <!--RoadDetails
        v-if="isShowRoadDetails"
        class="road-details-zone"
        :mapHighlightedFeatureItem="mapHighlightedFeatureItem"
        :vectorDetails="selectedVectorDetails"
        :vectorData="mapVectorSourceFeatures"
        @setHighlightedFeatureItem="setMapHighlightedFeatureItem"
      ></RoadDetails-->
      <!--/transition-->
    </div>
  </div>
</template>
<script>
import OlMap from '@/components/OlMap'
import {mapState} from 'vuex'

export default {
  name: 'country-map-page',
  data() {
    return {
      tileTypes: [],
      vectorTypes: [],
      selectedTileType: 'osm',
      selectedVectorsType: 'bridges',
      maper: '',
      mapExtent: [],
      mapVectorSourceFeatures: [],
      mapHighlightedFeatureItem: null,
      isMapDefaultParamsLoaded: false,
      pagestyle: '',
      filterQueryDef: {},
      filterList: [],
      isLoadingFilterListData: false,
      currentUrl: 'bridges-tunnels-map'
    }
  },
  components: {
    OlMap
    //RoadDetails,
  },
  async created() {
    const {
      vector,
      tile,
      state = '',
      filter_by = [],
      filter_values = [],
      pagestyle
    } = this.$route.query

    this.pagestyle = pagestyle

    if (tile) {
      this.selectedTileType = tile
    }

    if (state) {
      const attr = JSON.parse(atob(state))
      if (typeof attr === 'object') {
        const {extent} = attr
        this.mapExtent = extent
      }
    }

    if (filter_by) {
      if (!Array.isArray(filter_by)) {
        this.filterQueryDef[filter_by] = filter_values
      } else {
        filter_by.forEach((field, index) => {
          this.filterQueryDef[field] = filter_values[index]
        })
      }
    }

    if (vector) {
      this.selectedVectorsType = vector
    }

    this.$store.dispatch('LOAD_VECTOR_TYPES').then((res) => {
      this.vectorTypes = [...res]
    })

    // this.$store.dispatch('LOAD_MAP_DEFAULT_PARAMS').then(() => {
    this.isMapDefaultParamsLoaded = true

    // })
  },

  methods: {
    async reloadVectorData(payload) {
      const {layer, vectorData, cb} = payload
      console.time('dataLoad')
      try {
        const dataGeoJSON = await this.$store.dispatch('LOAD_COORDS', {
          layer,
          vectorData
        })
        cb({layer, source: dataGeoJSON})
      } catch (err) {
        console.log(`Error onLoadLayerData : ${err}`)
      } finally {
        console.timeEnd('dataLoad')
      }
    },

    setMapHighlightedFeatureItem(payload) {
      const {eventPointInfo} = payload
      this.mapHighlightedFeatureItem = eventPointInfo
    },

    onMapVectorSourceIsLoading(state) {
      if (state) {
        this.mapVectorSourceFeatures = []
      } else {
        // if (this.selectedRoads.length > 0) {
        //   const chartData = this.$refs.mapInstance.getDataForChart('roadsLayer')
        //   this.mapVectorSourceFeatures = [...chartData]
        // }
      }
    },

    routerQueryUpdate(params) {
      const modQuery = {...this.$route.query}
      Object.keys(params).forEach((item) => {
        if (params[item] === 'none') {
          delete modQuery[item]
        } else {
          modQuery[item] = params[item]
        }
      })
      this.$router.push({path: this.currentUrl, query: modQuery})
    },

    onChangeTileList(list) {
      this.tileTypes = [...list]
    },

    onChangeMapState(payload) {
      const map_state = btoa(JSON.stringify({...payload}))
      this.routerQueryUpdate({state: map_state})
    },

    onTileTypeChanged(tile) {
      this.routerQueryUpdate({tile})
    },

    async onVectorTypeChanged(type) {
      this.routerQueryUpdate({
        vector: type,
        filter_by: 'none',
        filter_values: 'none'
      })
    }
  },
  computed: {
    ...mapState({
      mapDefaultParams: (state) => state.Bridges.mapDefaultParams
    }),

    selectedVectorLegend() {
      return this.selectedVectorDetails && this.selectedVectorDetails.legend
        ? this.selectedVectorDetails.legend
        : []
    },

    selectedVectorDetails() {
      return this.vectorTypes.find(
        (vector) => vector.key === this.selectedVectorsType
      )
    },
    isShowRoadDetails() {
      return false
      // const isExistsGraph =
      //   this.selectedVectorDetails &&
      //   this.selectedVectorDetails.hasOwnProperty('graph')

      // return (
      //   this.selectedRoads.length > 0 &&
      //   this.vectorTypes.length > 0 &&
      //   isExistsGraph
      // )
    },
    filteredRoadsList() {
      const searchStr = this.roadSearchValue
        ? this.roadSearchValue.replace(/\(|\)/gi, (str) => `\\${str}`)
        : ''
      const re = new RegExp(searchStr, 'i')
      return this.roadsList.length > 0
        ? this.roadsList.filter(
            (item) => re.test(item.roadcode) || re.test(item.road_name)
          )
        : []
    },
    roadSearchList() {
      return this.filteredRoadsList.map((road) => {
        return {name: road.road_name, code: road.roadcode}
      })
    }
  }
}
</script>
<style lang="scss">
.maps-area {
  position: absolute;
  padding: 0 12px;
  left: 0;
  //height: calc(100% - 100px);
  width: 100%;
}
.dropdown-menu-pos {
  top: 35px;
  max-height: 300px;
  overflow: auto;
}
.main-zone {
  display: flex;
  flex-direction: column;
  height: calc(90vh - 70px);
}
.md-icon-clear {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
